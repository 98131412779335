@import './_projspecific_vars.scss';

// enable google fonts
$td-enable-google-fonts: true;
$google_font_name: "Roboto";
$google_font_family: "Roboto:100i,200i,300i,400i,100,200,300,400,500,700";
$font-awesome-font-name: "Font Awesome 6 Free" !default;
//$font-family-default: 'Segoe UI';



//@import "_navbar";

/*

Nothing defined here. The Hugo project that uses this theme can override theme variables by adding a file to:

assets/scss/_variables_project.scss

*/

/* Heading sizes */

$h1-font-size: 2rem !default;
$h2-font-size: 1.5rem !default;
$h3-font-size: 1.25rem !default;
$h4-font-size: 1.15rem !default;
$h5-font-size: 1rem !default;
$toc-font-size: 1rem !default;
/* $nav-font-size: 1rem !default; not sure needed */


/* Colors */

$background: #30638e !default;
$light-grey: #A9A9A9 !default;
$darker-grey: #404040 !default;
$blue-hover: #397BB7 !default;
$white: #ffffff !default;
$copy-button-before: #0F6CBD !default;
$copy-button-hover:#005f94 !default;
$copy-button-active: #28a745 !default;
$colorNeutralForeground1-light: $darker-grey !default;

/* Alert colours */
/* Inline notifications */

$primary: #30638E !default;
$info: #30638E !default;
$success: #38BF6C !default;
$warning: #BF0000 !default;

$primary-inline: #00D7ED;
$info-inline: #767FE7;
$success-inline: #38BF6C;
$warning-inline: #BF0000;
$primary-multiple: #00D7ED;

/* Everything above this will be intended for general purpose */


/* Everything below this is from Bolt (FIGMA) */

/* DARK - all */
/* All variations of dark are acceptable text color on light100 bg */
/* Only dark900 and dark800 are allowed on a blue100 bg
/* Only dark900 is allowed on a blue400 bg */

$dark300: #4B4E53; // meta data font on light bg
$dark400: #424448;
$dark500: #3D3F43;
$dark700: #313335; // default for text and icons on light bg
$dark800: #2A2B2D;
$dark900: #000000; // for accessibility


/* LIGHT - all */
/* Only light100 text is permitted on a blue800 bg for accessibility */

$light100: #FFFFFF; // for accessibility
$light200: #FAFAFA;
$light300: #E4E5E7;
$light400: #D6D8DC;
$light500: #C8CCD0;
$light600: #BABFC4; // hint text on dark bg & disabled text and icons on dark bg
$light700: #AAAFB6;
$light900: #606263; // disabled text and icons on light bg


/* Typography */

// Title header Typography Tokens 
//$font-family-base: 'Segoe UI';
$font-size-900: 43px;
$line-height-900: 52px;
$letter-spacing-none: 0px;
$font-weight-lighter: 350;

// Body 1 Typography Tokens
$banner-font-size: 16px;
$button-font-size: 16px; 
$line-height: 1.5rem;
$font-weight-regular: 400;

// Body 1 Stronger Typography Tokens 
$font-weight-bold: 500;

// Text Typography Tokens 

$font-family-text: 'Roboto', sans-serif;

// h-lg Typography Tokens 
$h-lg-font-size: 23px;
$h-lg-line-height: 22px;

// h2 Typography Tokens 
$h-xl-font-size: 30px;
$h-xl-line-height: 35.16px;
$font-weight-light: 300;

//Text Typography Token
$font-size-100: 17px;
