.td-navbar-cover {
    background: $primary;

    @include media-breakpoint-up(md) {
        //background: transparent !important;
        .nav-link {
            color: $white;
            text-shadow: none;
        }

    }

    &.navbar-bg-onscroll .nav-link {
        text-shadow: none;
    }
}

.ul-1 .with-child > input:checked ~ label:before {
    color: $light200;
    transform: rotate(90deg);
    transition: transform 0.5s;
}

.navbar-bg-onscroll {
    background: $white;
    opacity: inherit;
    border-bottom: 1px solid #D3D3D3; /* Light Gray */
    box-shadow: 0 8px 6px -6px #D3D3D3
}

.td-navbar {
    background: $white;
    border-bottom: 1px solid #D3D3D3; /* Light Gray */
    box-shadow: 0 8px 6px -6px #D3D3D3; 
    min-height: 4rem;
    z-index: 32;

    @include media-breakpoint-up(md) {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .navbar-brand {
        text-transform: none;
        .nav-link {
            display: inline-block;
        }
        svg {
            display: inline-block;
            margin: 0px 10px;
            height: 25px;
        }
       
    }

    .nav-link {
        color: #202020 !important;
        text-transform: none;
        font-weight: 400;
    }
    
	// Not being respect post 0.4.0, see _search.scss instead
    .td-search__input {
        border: none;
        color: $navbar-dark-color;
        @include placeholder {
            color: $navbar-dark-color;
        }
    }

    .dropdown {
        min-width: 100px;
    }

    @include media-breakpoint-down(md) {
        padding-right: .5rem;
        padding-left: .75rem;

        .td-navbar-nav-scroll {
            max-width: 100%;
            height: 2.5rem;
            margin-top: .25rem;
            overflow: hidden;
            font-size: .875rem;
            
            .nav-link {
                color: #202020 !important;
                text-transform: none;
                font-weight: 400;
                font-size: 10px;
                margin-top: 5px;
            }

            .navbar-nav {
                padding-bottom: 2rem;
                overflow-x: auto;
                white-space: nowrap;
                -webkit-overflow-scrolling: touch;

            }
            
        }
        .navbar-brand {
            text-transform: none;

            .nav-link {
                display: inline-block;
            }
    
            svg {
                display: inline-block;
                margin: 0px 10px;
                margin-top: 0;
                height: 25px;
            }
            
           .nav-name{
                font-size: 14px;
           }
        }
    }
    @include media-breakpoint-down(sm) {
        padding-right: .5rem;
        padding-left: .75rem;

        .td-navbar-nav-scroll {
            
            .nav-link {
                color: #202020 !important;
                text-transform: none;
                font-weight: 400;
                font-size: 10px;
                margin-bottom: 0px;
            }
            
        }
        .navbar-brand {
            text-transform: none;
            .nav-link {
                display: inline-block;
            }
    
            svg {
                display: inline-block;
                height: 25px;
            }
            
           .nav-name{
                font-size: 12px;
           }
        }
    }
}

@media (hover: hover) and (pointer: fine) {

    nav.foldable-nav {

        .ul-1 .with-child > label:hover:before {
            color: $light200 !important;
            transition: color 0.3s;
        }

        .ul-1 .with-child > input:checked ~ label:hover:before {
            color: $light200 !important;
            transition: color 0.3s;
        }
    }
}


@media (min-width: 768px) {
    
    .separator {
      border-left: 2px solid #737373; /* Slightly lighter dark gray */
      height: 20px;  /* Decrease the height */
      margin-top: 10px;
    }
  }
  .site_logos {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    width: 45%;  /* or adjust as needed */
  }
  
  @media (max-width: 767px) {
    .separator {
      visibility: hidden;
    }
    .site_logos {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center; /* This will center the items horizontally */
        width: 100%;
    }
  }

