body:lang(fa),
body:lang(ar),
body:lang(az),
body:lang(dv),
body:lang(he),
body:lang(ku),
body:lang(ur) {

    @import 'spacing';

    direction: rtl;
    text-align: right;

    .dropdown-menu {
        text-align: right;
    }

    .text-right {
        text-align: left !important;
    }

    pre {
        text-align: left;
        direction: ltr;
    }

    .td-rss-button {
        left: 1rem !important;
        right: auto !important;
    }
}

body:lang(fa) {
    @import url('https://cdn.jsdelivr.net/gh/rastikerdar/vazir-font@v27.0.1/dist/font-face.css');
    font-family: 'Vazir', "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body:lang(he) {
    @if $td-enable-google-fonts {
        @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');
    }
    font-family: 'Rubik', "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body:lang(ar) {
    @if $td-enable-google-fonts {
        @import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;700&display=swap');
    }
    font-family: 'Tajawal', "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

