// landing page

.btn-primary:hover {
    border-color: $blue;
}

.display-1 {
    color: $dark700;
}

.lead {
    color: $dark700;
}

// toc styling

.td-sidebar-nav .td-sidebar-link__page {
    color: $light200;
}

.td-sidebar-nav__section li {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.td-sidebar-nav__section-title a {
    color: $light200;
    display:block;
}

.td-sidebar {
    background-color: $dark700;
    list-style: none;
    padding-left: 0px;
    display: inline-block;
    width: 100%;
}

 .toc #TableOfContents {
    display: block !important;
 }

 .toc li a.active {
    color: $dark900 !important;
    font-weight: 500 !important;
 }

  .toc ul ul > li > a {
    padding-left: 1rem !important;
 }

 // need to figure out how to make entire row as active

.td-sidebar-nav a.active {
    background: $dark400;
    border-bottom: 2px solid $activenavlink;
    padding: 5px;
    font-weight: normal;
    margin-bottom: 5px;
}

.td-sidebar-nav a:hover {
    color: $blue400;
}

nav.foldable-nav .ul-1 .with-child > label:before {
    color: $light200;
}

nav.foldable-nav .ul-1 .with-child > input:checked ~ label:before {
    color: $light200;
}

.td-sidebar-nav .td-sidebar-link.tree-root {
    display: none;
}

// tablet landscape (1024x768)
@media only screen and (max-width: 1024px) {
    .btn:not(:disabled):not(.disabled) {
        color: $light200;
    }
    .td-search__input {
        font-size: 0.875rem;
    }
    .td-main .col-lg-4 {
        max-width: 100% !important;
    }
    .td-overlay {
        background-image:
          linear-gradient(97.33deg, $babyblue 5.65%, $lightblue 94.26%);
        background-repeat:
          no-repeat,
          no-repeat !important;
        background-position:
          right,
          right !important;
        background-size:
          cover,
          cover !important;
    }
}

.navbar-brand__name {
    color: #424242;
	font-weight: 400;
}
footer {
    min-height: 75px;

    @include media-breakpoint-down(lg) {
        min-height: 100px;
    }
}
.td-main h2.landing {
    margin-left: 0px;
}
h1.display-1.mt-0.mt-lg-5.pb-4 {
    font-size: 2.2rem;
    padding-top: 10px;
}

.text-center {
    padding-top: 20px;
}
.mt-lg-5 {
    margin-top: 0px;
}

