// Mixins

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root("::-webkit-input-placeholder") {
        @content;
    }


    @include optional-at-root(":-moz-placeholder") {
        @content;
    }


    @include optional-at-root("::-moz-placeholder") {
        @content;
    }


    @include optional-at-root(":-ms-input-placeholder") {
        @content;
    }
}

// Common util classes.

.td-border-top {
    border: none;
    border-top: 1px solid #eee;
}

.td-border-none {
    border: none;
}

.td-block-padding {
    padding-top: $td-block-space-top-base ;
    padding-bottom: $td-block-space-bottom-base;

    @include media-breakpoint-up(md) {
        padding-top: $td-block-space-top-base * 1.25;
        padding-bottom: $td-block-space-bottom-base * 1.25;
    }
}

.td-overlay {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &--dark::after {
        background-color: rgba($dark, 0.3);
    }

    &--light::after {
        background-color: rgba($light, 0.3);
    }

    &__inner {
        position: relative;
        z-index: 1;
    }
}

.td-max-width-on-larger-screens {
    @include media-breakpoint-up(lg) {
        max-width: 80%;
    }

}