
/* BLUE - all */
/* blue800 is the only acceptable variation on light bg */

$blue100: #E9F5FF;
$blue400: #7AC7FF; // hover for text and icons on dark bg
$blue600: #007BBD;
$blue800: #065D93;


/* gradient variations for navigation banner */

$darkblue: #141380;
$lightblue: #2DC6D6;

$darkgrey: #2A2B2D;
$navyblue: #141380;

/* gradient for landing page */

$offwhite: #FAFAFA;
$babyblue: #D1ECF0;


/* mix oriented behavior elements */

/* landing page */
$landinggradientdark: $babyblue;
$landinggradientlight: $offwhite;
$landinglinks: $blue600;
$landingbutton: $blue600;
$landingicons: $lightblue;

/* left nav */
$activenavlink: $blue400;

/* main nav */
$searchboxborder: $blue400;
$navgradientdark: $darkgrey;
$navgradientlight: $navyblue;

/* content area */
$linkcolor: $blue800;
$linkhover: $blue800;

/* right toc */
$printbutton: $blue600;
