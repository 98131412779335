.section-index {

    .entry {
        padding: .75rem;
    }

    h5 {
        margin-bottom: 0;

        a {
            font-weight: 700;
        }
    }

    p {
        margin-top: 0;
    }
}
