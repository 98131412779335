.pageinfo {
    font-weight: $font-weight-medium;
    background: $gray-100;
    color: inherit;
    border-radius: 0;
    margin: 2rem;
    padding: 1.5rem;
    padding-bottom: 0.5rem;

    @each $color, $value in $theme-colors {
        &-#{$color} {

            border-style: solid;
            border-color: $value;
        }
    }
   
}
