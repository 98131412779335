@import './_projspecific_content.scss';

// landing page

.td-main .col {
     margin:auto;
}

.td-main .row {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.td-main h2.landing {
    //padding-left: 4vw;
    padding-right:4vw;
    padding-top: 2rem;
    //margin-left: -15px;
    margin-right: -15px;
    display: block;
    width: 100%;
}

.td-main .col-lg-4 {
    max-width: 18% !important;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-right: 1.5em;
    margin-bottom: 1.5em !important;
	box-sizing: border-box;
    border-radius: 8px;
	box-shadow: 2px 4px 8px 2px #cbcbcb;
    background: $white;
}


//
// Style Markdown content
//

.td-content {
    order: 1;

    p, li, td, div.alert, div.highlight {
        font-weight: $font-weight-body-text;
        //font-size: 0.9rem;
        //margin-bottom: 0.5rem;
    }

    //div.highlight {margin-bottom: 1rem;}

    .alert:not(:first-child){
        margin-top: 1rem !important;
        margin-bottom: 1.5rem !important;
    }

    //code { font-size: 0.875rem; }

    > h1,h2 {
        font-weight: $font-weight-medium;
        margin-bottom: 1rem;
    }

    > h2:not(:first-child) {
        margin-top: 3rem;
    }

    > h2 + h3 {
         margin-top: 1rem;
    }

    > h2 + blockquote {
        display: none;
    } // NUANCE To remove from imported Slate-MD files blockquote, usu. before code, directly after a heading since repetitive

    > h3, > h4, > h5, > h6 {
        margin-bottom: 1rem;
        margin-top: 2rem;
        font-weight: $font-weight-medium;
        line-height: 1rem;
    }

    img {
        @extend .img-fluid;
    }


    > blockquote,.expand-content blockquote {
        padding: 0 0 0 1rem;
        margin-bottom: $spacer;
        color: $gray-600;
        border-left: 6px solid $secondary;
    }

    > ul li, > ol li {
        margin-top: .25rem;
        margin-bottom: .25rem;
    }

    //NUANCE additions for list styling
    ol ol {
        /* second level numbered lists */
        list-style-type: lower-alpha;
    }

    ol ol ol,ul ol ol {
	/* third level numbered lists, hopefully rare */
	list-style-type: lower-roman;
    }

    ul ul,ol ul {
	/* second level bullet lists */
	list-style-type: circle;
    }

    ul ul ul,ol ul ul    {
	/* third level bullet lists, hopefully rare */
	list-style-type: square;
    }

    strong {
        font-weight: $font-weight-bold;
    }

    > pre, > .highlight, > .lead, > h1, > h2, > ul, > ol, > p, > blockquote, > dl dd, .footnotes, > .alert, .expand , .struct .highlight, .tab-pane .highlight {
        @extend .td-max-width-on-larger-screens;
    }

    // NUANCE addition for expands within lists
    > ul > li > .expand, > ol > li > .expand {
        max-width: 100%;
    }

    // NUANCE addition to reduce spacing above code block
    .highlight {
        margin: 1rem 0;
    }

    // NUANCE addition to add spacing before and after an expand shortcode
    .expand {
        margin: 1rem 0;
    }

    // NUANCE addition for glossary tooltip links
    .glossary-tooltip {
        display: inline-block;
        border-bottom: 1px dotted #000;
        color: #222;
        text-decoration: none !important;
    }

    .alert:not(:first-child) {
        margin-top: 2 * $spacer;
        margin-bottom: 2 * $spacer;
    }

    .lead {
        margin-bottom: 1.5rem;
    }

    // NUANCE addition to hide content
    .hide {
        display: none;
    }

    //* NUANCE caption force to top and no display */
    caption{
        caption-side: top;
        display: none;
    }

}

.td-title {
    margin-top: 1rem;
    margin-bottom: .5rem;

    @include media-breakpoint-up(sm) {
        font-size: 3rem;
    }
}

//NUANCE addition
.td-404 div.center {
    padding:2rem;
}

//NUANCE tweaks, moved out of .td-content due to expand and other interleaving divs
table {
    @extend .table-responsive;

    @extend .table;

    @extend .table-hover; //NUANCE addition

    max-width: 80%; //NUANCE addition

    display: table; //NUANCE addition
}

thead { @extend .table-primary; }

.expand-content table, ul table, ol table {
    max-width: 100%;
}

/* NUANCE addition to remove extraneous first item in lh nav */
.td-sidebar-nav .td-sidebar-link.tree-root {
    display: none;
}

.td-sidebar-nav { margin-top: 1rem; }

.Title1 {
    font-family: $google_font_name, sans-serif;
    font-size: $font-size-900;
    line-height: $line-height-900;
    letter-spacing: $letter-spacing-none;
    font-weight: $font-weight-light;
}

/* Reduce font size for screens smaller than 450px */
@media (max-width: 449px) {
    .Title1 {
        font-size: 30px; /* Adjust this value as needed */
    }
}

.Body1 {
    font-family: $google_font_name, sans-serif;
    font-size: $banner-font-size;
    line-height: $line-height;
    letter-spacing: $letter-spacing-none;
    font-weight: $font-weight-regular;
    text-align: center;
}

.Body1_stronger {
    font-family: $google_font_name, sans-serif;
    font-size: $banner-font-size;
    line-height: $line-height;
    letter-spacing: $letter-spacing-none;
    font-weight: $font-weight-bold !important;
    text-align: center;
}

.text {
    font-family: $google_font_name;
    font-size: $button-font-size;
    line-height: $line-height;
    font-weight: $font-weight-regular;
    text-align: center;
    color: $colorNeutralForeground1-light;
    padding: 5px;
}

.h-lg {
    font-family: $google_font_name;
    font-size: $h-lg-font-size;
    line-height: $h-lg-line-height;
    font-weight: $font-weight-light;
    text-align: center;
    color: $colorNeutralForeground1-light;
}

.landing {
    font-family: $google_font_name, sans-serif;
    font-size: $h-xl-font-size;
    line-height: $h-xl-line-height;
    font-weight: $font-weight-light;
  }

.Intro-Landing-Page{
    //width: 50%;
}

.welcome_button{
    border-radius: 8px;
    font-family: $google_font_name, sans-serif;
    font-size: $button-font-size;
    line-height: $line-height;
    letter-spacing: $letter-spacing-none;
    font-weight: $font-weight-bold !important;
    text-align: center;
}

.landing_page_button {
    font-family: $google_font_name, sans-serif;
    font-size: $button-font-size;
    line-height: $line-height;
    letter-spacing: $letter-spacing-none;
    font-weight: $font-weight-bold !important;
    color: $colorNeutralForeground1-light;
    text-align: center;
    /* Responsiveness for screens smaller than x-large */
    @media (max-width: 1480px) { 
        font-size: 12px;
        
    }
}