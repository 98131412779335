.td-card-deck.card-deck {
  @extend .td-max-width-on-larger-screens;
}

.td-card {
  &.card {
    @extend .td-max-width-on-larger-screens;

    .highlight {
      border: none;
    }
  }

  .card-body {
    &.code {
      background-color: #f8f9fa;
      padding: 0 0 0 1ex;
    }

    pre {
      margin: 0;
      padding: 0 1rem 1rem 1rem;
    }
  }
}