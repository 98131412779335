// Code formatting.
.highlight:hover .copy-button
 {
    opacity: 1;
}

.highlight:hover .copy-button-clicked
{
    opacity: 1;
}

.td-content {
	// Highlighted code.
    .highlight {
        @extend .card;
	
        margin: 2rem 0;
        padding: 0;
        position: relative;

        .click-to-copy {
            display: block;
            text-align: right;
            height: 1ex;
        }

        pre {
            margin: 0;
            padding: 1rem;

            // Default click-to-copy button

            button {
                position: absolute;
                top: 0;
                right: 0;
                transition: $copy-button-before 0.3s ease, opacity 0.3s ease;
                z-index: 1;
                margin: 4px;
                padding: 7px 20px; /* add padding to give the button more space */
                border-radius: 7px; /* increase border-radius to make edges more rounded */
                font-size: 14px; /* adjust as needed */
                opacity: 0; /* hidden by default */
                background-color: $copy-button-before;
                color: $white;
                border-color: none;
                box-shadow: none;
                border: none;

               &:hover {
                color: $white;
                background-color: $copy-button-hover;
                border-color: none;
                box-shadow: none;
                border: none;
               }

               &.copy-button-clicked {
                color: $white;
                background-color: $copy-button-active;
                border-color: none;
                box-shadow: none;
                border: none;
               }
            }
        }
    }
 }

    // Inline code
    p code, li > code, table code {
        color: inherit;
        padding: 0.2em 0.4em;
        margin: 0;
        font-size: 85%;
        word-break: normal;
        background-color: rgba($black, 0.05);
        border-radius: $border-radius;

        br {
            display: none;
        }
    }
	
	
	// Nuance specific
	code {
		background-color: $light200;
		color: $dark700;
	}


    // Code blocks
    pre {
        word-wrap: normal;
        background-color: $gray-100;
        padding: $spacer;
     

        > code {
	    background-color: inherit !important;
            padding: 0;
            margin: 0;
            font-size: 100%;
            word-break: normal;
            white-space: pre;
            border: 0;
        }
    }

    pre.mermaid {
        background-color: inherit;
        font-size: 0;
    }
