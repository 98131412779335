.btn-primary {
    background: $blue600;
    border-color: $blue600;
}

.btn-primary:hover {
    background: $blue600;
}

.btn-primary:focus, .btn-primary.focus {
    background: $blue600;
    border-color: $blue600;
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
    background: $blue600;
    border-color: $blue600;
}

.td-overlay {
    background-image:
      url("../images/landing-page/MIX-cloud-stars-righthand-side.svg"),
      url("../images/landing-page/MIX-clouds-stars-planet-lefthand-side.svg"),
      linear-gradient(180deg, #FAFAFA 17.65%, #B5E7EC 96.26%);
      //url("../images/landing-page/pattern.svg"),
      //linear-gradient(97.33deg, #D1ECF0 5.65%, rgb(45, 198, 214) 94.26%);
    background-repeat:
      no-repeat,
      no-repeat,
      no-repeat,
      repeat,
      no-repeat !important;
    background-position:
      right 90px bottom -15px,
      left -30px bottom -230px,
      right,
      right,
	  right !important;
    background-size:
      300px 400px,
      450px 550px,
      cover,
      6%,
      cover !important;
}

.td-overlay--dark::after {
    background-color: rgba(0,0,0,0.0);
}

/* alternating background gradient for rows */
.td-box--first .row:nth-child(odd) {
    background: linear-gradient(97.33deg, $landinggradientlight 5.65%, $landinggradientdark 94.26%);
}

.td-box--second .row:nth-child(odd) {
    background: linear-gradient(97.33deg, $landinggradientdark 5.65%, $landinggradientlight 94.26%);
}

.icon-color {
    color: $landingicons;
}

.col-lg-4 a {
    background-color: $landinglinks;
    border-radius: 7px;
    padding: 10px;
    color: $white;
}
