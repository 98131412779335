
// fix for tabpanes in a list 
li .tab-content .tab-pane {
    max-width: 100%;
}

.toc ul ol {
    padding-left: 1.5em !important;
}

// left navigation

nav.foldable-nav .with-child {
    padding-left: 1em;
}

nav.foldable-nav .ul-1 > li {
    padding-left: 15px !important;
}

nav.foldable-nav#td-section-nav {
    padding-left: 10px;
    padding-top: 20px;
}

nav.foldable-nav .without-child {
    padding-left: 0px;
}

nav.foldable-nav .ul-1 .with-child > label:before {
    content: "\f054";
    padding-left: 0px;
    padding-right: 0px;
}

nav.foldable-nav#td-section-nav label {
    padding-left: 5px;
}

.td-sidebar-nav__section {
    padding: 0px;
}


// tabbed code blocks [remove marker when used in numbered list]

ul.nav.nav-tabs > li.nav-item {
    list-style-type: none;
}

.tab-content > .tab-pane.show.fade.active > .highlight {
    margin-top: 0rem;
}

.nav-tabs {
    border-bottom: none;
}


// breadcrumbs

.breadcrumb-item.active > a.btn-link.disabled {
    color: $light900;
    font-weight: 500;
}

.breadcrumb-item + .breadcrumb-item::before {
    font-family: $font-awesome-font-name;
    content: "\f054";
    color: $light500;
}

.td-breadcrumbs .breadcrumb {
    font-weight: 500;
}


// table

table td {
    border: none;
}

thead th {
    border: none;
}

thead {
    background-color: $light300;
}

tr:nth-child(even) {
    background-color: $light200;
  }


// expand with code block

.td-content .expand .expand-content .highlight {
    max-width: 100% !important;
}

// code blocks

.highlight pre {
    padding: 15px;
}

// feedback

.feedback--title {
    margin-top: 3rem;
}

.feedback--answer {
    background-color: $printbutton;
    text-align: center;
    font-weight: 400;
    width: 80px;
    border-radius: 9px;
}


// search box in toc

.td-navbar .td-search__input {
    background: #fff;
    border: 1px solid #807EA3;
    box-sizing: border-box;
    //box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.6);
    border-radius: 7px;
    width: 100%;
}

.td-search__input {
    margin-right: 15px;
    font-family: 'Roboto';

    @include placeholder {
        color: #30638E !important;
    }

    &.form-control:focus {
        box-shadow: none;
        border-color: $searchboxborder;
        color: #30638E;
    }

    @if $enable-rounded {
        //border-radius: 1rem;
    }
}

.td-navbar .td-search__icon {
    color: #30638E;
}

.td-sidebar__search {
    width: 70%;
    padding: 1rem 15px;
    margin-right: auto;
    margin-left: auto;
}


// navbar

.td-navbar .nav-link {
    color: $light200;
    font-weight: normal;
}

.navbar-brand__name {
    color: $light200;
	font-weight: 400;
}

// child list index pages

.section-index h5 a {
    font-weight: 500;
}

.section-index h5 a:hover {
    color: $linkhover;
}


// print page button

.td-toc .td-page-meta a {
    background-color: $printbutton;
    color: $white;
    border-radius: 9px;
    text-align: center;
    padding: 3px;
    font-weight: 400;
}


// link elements all

a {
    color: $linkcolor;
}

/* add underline to links only found in content area */
.td-content * a:hover {
    text-decoration: underline;
}

// anchor links 

a.name-anchor { 
    scroll-margin-top: 80px;
    text-decoration: none;
    color: $dark900;
}

a.name-anchor:hover {
    text-decoration: none;
}

/* add underline to Notice link in footer */
footer span.static a:hover {    
    text-decoration: underline;
}

/* customizing to change from normal text on keyboard focus */
footer span.static a:focus-visible {
	font-weight: bold;
    text-decoration: underline;
}

// headings all

.td-content > h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 5px; // for testing
}


// page description

.td-content .lead {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}


// pill capsule for call-outs (engine-pack-version uses this)

.pill-style {
    border-radius: 9px;
    border: 1px solid $dark700;
    box-sizing: border-box;
    padding: 10px;
    display: inline-block;
 }


 // pageinfo styling with all color params

 .pageinfo .pageinfo-heading {
    color: $dark900;
    font-weight: 500;
    font-size: inherit;
    display: inline-block;
 }

 .pageinfo .pageinfo-body {
     display: initial;
 }

 .pageinfo-primary .icon::before {
    font-family: $font-awesome-font-name;
    font-style: normal;
    content: "\f058";
    padding-right: 5px;
    color: $primary-inline;
 }

 .pageinfo {
    margin: 2rem 2rem 2rem 0rem;
    border-radius: 9px;
    border: 1px solid $dark700;
    box-sizing: border-box;
    padding: 15px 10px 20px 10px;
    background: -webkit-linear-gradient(90deg, $primary-inline 10px, $light200 0%);
 }


 // NUANCE custom struct div element for setting off description of API structure
 .td-content div.struct+p,.td-content div.struct .highlight > pre {
    background-color: rgba(0,140,255,0.05) !important;
    border-radius: 4px !important;
    box-sizing: border-box;
    display:block;
    padding: 1em;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 0.875rem;
 }

 .td-content div.struct+p {
     border: 1px solid rgba(0,0,0,.125) !important;
 }

 .td-content pre > code {
    background-color: rgba(0,0,0,0.0) !important;
 }

 .td-content > pre.mermaid {
     padding: 0;
     padding-bottom: 1rem;
     max-width: 100% !important;
 }

// Figma updated inline notification design

.td-content .alert ul ul {
    margin-top: 0;
}

.td-content ul li ul li {
    margin-top: 0;
}

.td-content .alert ul {
    margin-top: 0.5rem;
    margin-bottom: 0rem;
}

.alert  .alert-heading {
    color: $dark900;
    font-weight: 500;
    font-size: inherit;
    display: inline-block;
    margin-bottom: 0px;
 }

.alert .alert-body {
    display: initial;
}

.alert {
    border-radius: 9px;
    border: 1px solid $dark700;
    box-sizing: border-box;
    padding: 15px 10px 20px 10px;
    background: -webkit-linear-gradient(90deg, $primary-inline 8px, $light200 0%);
 }

 .alert-primary .icon::before {
    font-family: $font-awesome-font-name;
    font-style: normal;
    content: "\f058";
    padding-right: 5px;
    color: $primary-inline;
 }

 .alert-primary-multiple .icon::before {
    font-family: $font-awesome-font-name;
    font-style: normal;
    content: "\f058";
    padding-right: 5px;
    color: $primary-multiple;
 }

 .alert-warning .icon::before {
    font-family: $font-awesome-font-name;
    font-style: normal;
    content: "\f058";
    padding-right: 5px;
    color: $warning-inline;
 }

 .alert-success .icon::before {
    font-family: $font-awesome-font-name;
    font-style: normal;
    content: "\f058";
    padding-right: 5px;
    color: $success-inline;
 }

 .alert-info .icon::before {
    font-family: $font-awesome-font-name;
    font-style: normal;
    content: "\f058";
    padding-right: 5px;
    color: $info-inline;
 }

 .alert-warning {
    background: -webkit-linear-gradient(90deg, $warning-inline 8px, $light200 0%);
 }

 .alert-success {
    background: -webkit-linear-gradient(90deg, $success-inline 8px, $light200 0%);
 }

 .alert-info {
    background: -webkit-linear-gradient(90deg, $info-inline 8px, $light200 0%);
 }


 // General CSS overrides, some are in _content.scss and _variables_project.scss overrides

.td-content .lead {
    font-style: italic;
    font-size: 1rem;
 }


 // footer

 .bg-dark {
    background-color: $dark900 !important;
 }

 footer.pb-5, footer.py-5 {
     padding-top: 0.75rem !important;
     padding-bottom: 0.5rem !important;
 }

 footer {
     min-height: 75px;

     @include media-breakpoint-down(md) {
         min-height: 100px;
     }
 }

// Media queries below

// Desktop for include-file
@media only screen and (min-width: 992px) {
    .include-file {
        max-width: 80%;
    }
    .include-file table {
        max-width: none !important;
    }
    .include-file .tab-pane {
        max-width: none !important;
    }
    .include-file .expand {
        max-width: 100%;
    }  
    .mx-auto .include-file {
        max-width: 100%;
    }
}


// mobile portrait (320x480)
@media only screen and (max-width: 320px) {
    .td-breadcrumbs .breadcrumb {
        margin-top: 15px;
    }
    .btn:not(:disabled):not(.disabled) {
        color: $light200;
    }
    .td-content > h3 {
        line-height: 1.5rem;
    }
    .td-main .col-lg-4 {
        max-width: 100% !important;
        margin-right: 0 !important;
     }
    .text-center {
        padding-top: 20px;
    }
    .pb-4 {
        padding-bottom: 0px !important;
    }
    .mx-auto p {
        font-size: 1rem;
    }
    .btn-primary {
        font-size: 1rem;
    }
    .td-main h2.landing {
        margin-left: 0px;
    }
    h1.display-1.mt-0.mt-md-5.pb-4 {
        font-size: 1.8rem;
        padding-top: 10px;
    }
    .td-search__input.form-control:focus {
        color: #30638E
    }
    .td-search__icon {
        color: #30638E;
    }
    .td-search__input {
        @include placeholder {
            color: #30638E !important;
        }
    
        &.form-control:focus {
            box-shadow: none;
            border-color: $searchboxborder;
            color: #30638E;
        }
    }
    
}


// mobile landscape (480x320)
@media only screen and (max-width: 480px) {
    .td-sidebar__inner {
        font-size: 0.875rem;
    }
    .td-search__input:not(:focus) {
        background-color: $white;
    }
    .td-search__input {
        font-size: 0.875rem;
        background-color: $white;
    }
    .td-main .col-lg-4 {
        max-width: 100% !important;
        margin-right: 0 !important;
     }
    .text-center {
        padding-top: 20px;
    }
    .pb-4 {
        padding-bottom: 0px !important;
    }
    .mx-auto p {
        font-size: 1rem;
    }
    .btn-primary {
        font-size: 1rem;
    }
    .td-main h2.landing {
        margin-left: 0px;
    }
    h1.display-1.mt-0.mt-md-5.pb-4 {
        font-size: 1.8rem;
        padding-top: 10px;
    }
    div.highlight {
        //max-width: 90% !important;
    }
    .td-content > table {
        max-width: 90% !important;
    }
    .td-search__input.form-control:focus {
       color: #30638E;
    }
    .td-search__icon {
        color: #30638E;
    }
    .td-search__input {
        @include placeholder {
            color: #30638E !important;
        }
    
        &.form-control:focus {
            box-shadow: none;
            border-color: $searchboxborder;
            color: #30638E;
        }
    }
}


// small tablet portrait (600x800)
@media only screen and (max-width: 600px) {
    .td-breadcrumbs .breadcrumb {
        margin-top: 15px;
    }
    .btn:not(:disabled):not(.disabled) {
        color: $light200;
    }
    .td-sidebar__inner {
        font-size: 0.875rem;
    }
    .td-search__input:not(:focus) {
        background-color: $white;
    }
    .td-search__input {
        font-size: 0.875rem;
        background-color: $white;
    }
    .td-main .col-lg-4 {
        max-width: 100% !important;
        margin-right: 0 !important;
    }
    .text-center {
        padding-top: 20px;
    }
    .mx-auto p {
        font-size: 1.2rem;
    }
    .pb-4 {
        padding-bottom: 0px !important;
    }
    .td-main h2.landing {
        margin-left: 0px;
    }
    h1.display-1.mt-0.mt-md-5.pb-4 {
        font-size: 2.2rem;
        padding-top: 10px;
   }
   .td-search__input.form-control:focus {
        color: #30638E;
   }
   .td-search__icon {
        color: #30638E;
    }
    .td-search__input {
        @include placeholder {
            color: #30638E !important;
        }
    
        &.form-control:focus {
            box-shadow: none;
            border-color: $searchboxborder;
            color: #30638E;
        }
    }
}

// small tablet landscape (800x600)
@media only screen and (max-width: 800px) {
    .btn:not(:disabled):not(.disabled) {
        color: $light200;
    }
    .td-sidebar__inner {
        font-size: 0.875rem;
    }
    .td-search__input:not(:focus) {
        background-color: $white;
    }
    .td-search__input {
        font-size: 0.875rem;
        background-color: $white;
        border-radius: 7px;
    }
    .text-center {
        padding-top: 20px;
    }
    .mt-md-5 {
        margin-top: 0px;
    }
    .pb-4 {
        padding-bottom: 0px !important;
        margin-top: -10px !important;
    }
    .td-main h2.landing {
        margin-left: 0px;
    }
    .td-content > table {
        max-width: 90% !important;
    }
    .td-search__input.form-control:focus {
        color: #30638E;
    }
    .td-search__icon {
        color: #30638E;
    }
    .td-search__input {
        @include placeholder {
            color: #30638E !important;
        }
    
        &.form-control:focus {
            box-shadow: none;
            border-color: $searchboxborder;
            color: #30638E;
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .td-sidebar__search {
        width: 100% !important;
    }
    .td-search__icon {
        color: #30638E;
    }
    .td-search__input.form-control:focus {
        color: #30638E;
    }
    .td-search__input {
        @include placeholder {
            color: #30638E !important;
        }
    
        &.form-control:focus {
            box-shadow: none;
            border-color: $searchboxborder;
            color: #30638E;
        }
    }
}

// tablet portrait (768x1024)
@media only screen and (max-width: 768px) {
    .btn:not(:disabled):not(.disabled) {
        color: $light200;
    }
    .td-sidebar__inner {
        font-size: 0.875rem;
    }
    .td-search__input:not(:focus) {
        background-color: $white;
    }
    .td-search__input {
        font-size: 0.875rem;
        background-color: $white;
        border-radius: 7px;
    }
    .td-main .col-lg-4 {
        max-width: 100% !important;
    }
    .td-search__input.form-control:focus {
        color: #30638E;
    }
    .td-search__icon {
        color: #30638E;
    }
    .td-search__input {
        @include placeholder {
            color: #30638E !important;
        }
    
        &.form-control:focus {
            box-shadow: none;
            border-color: $searchboxborder;
            color: #30638E;
        }
    }
}


// tablet landscape (1024x768)
@media only screen and (max-width: 1024px) {
    .btn:not(:disabled):not(.disabled) {
        color: $light200;
    }
    .td-search__input {
        font-size: 0.875rem;
        background-color: $white;
        border-radius: 7px;
    }
    .td-search__input:not(:focus) {
        background-color: $white;
    }
    .td-main .col-lg-4 {
        max-width: 100% !important;
    }
    .td-overlay {
        background-image:
          linear-gradient(97.33deg, #D1ECF0 5.65%, rgb(45, 198, 214) 94.26%);
        background-repeat:
          no-repeat,
          no-repeat !important;
        background-position:
          right,
          right !important;
        background-size:
          cover,
          cover !important;
    }
    .td-search__input.form-control:focus {
        color: #30638E;
    }
    .tab-content .tab-pane {
        max-width: 100%;
    }
    .td-search__icon {
        color: #30638E;
    }
    .td-search__input {
        @include placeholder {
            color: #30638E !important;
        }
    
        &.form-control:focus {
            box-shadow: none;
            border-color: $searchboxborder;
            color: #30638E;
        }
    }
}

@media print {
    .td-content > h1 {
        margin-top: 2rem;
     }
 }
 @import './_projspecific_styling.scss';
